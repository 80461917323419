a, h1, h2, h3, h4, h5, h6, p, ul, li, input, label, span, .modal-title {
  font-family: 'Primeform Pro', sans-serif !important;
}


.t-action-btn {
  display: flex;
  button {
    margin: 0 3px;
    padding: 4px 8px;
    font-size: 14px;
  }
}

.badge {
  font-size: 0.9em !important;
}
.del_cat_desc {
  max-width: 400px !important;

  overflow: auto !important;
}

.recipe_desc_add_container {
  border: 1px solid var(--falcon-input-border-color);
  border-radius: 8px;
  padding: 12px;
  position: relative;
  margin-bottom: 1rem;
}
.recipe_desc_add_container_removeBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 0;
  outline: 0;
  position: absolute;
  right: 12px;
  top: 12px;
}
.add_more_descBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 0;
  outline: 0;
  color: var(--falcon-body-color);
}
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}